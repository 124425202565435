import React, { useState, useEffect } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Title from "../components/Headings/Title"
import NotesList from "../components/Notes/NotesList"
import Search from "../components/Notes/Search"
import "../styles/notes.scss"

const Notes = () => {
  const [notes, setNotes] = useState([
    {
      id: 1,
      text: "This is example note!",
      date: "15/04/2021",
    },
  ])

  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    const savedNotes = JSON.parse(localStorage.getItem("react-notes-app-data"))

    if (savedNotes) {
      setNotes(savedNotes)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem("react-notes-app-data", JSON.stringify(notes))
  }, [notes])

  const addNote = text => {
    const date = new Date()
    const newNote = {
      id: text,
      text: text,
      date: date.toLocaleDateString(),
    }
    const newNotes = [...notes, newNote]
    setNotes(newNotes)
  }

  const deleteNote = id => {
    const newNotes = notes.filter(note => note.id !== id)
    setNotes(newNotes)
  }

  return (
    <Layout>
      <Seo title="Contact" />
      <section className="notes-section">
        <Title>To Do Notes...</Title>
        <div className="container py-5">
          <Search handleSearchNote={setSearchText} />
          <NotesList
            notes={notes.filter(note =>
              note.text.toLowerCase().includes(searchText)
            )}
            handleAddNote={addNote}
            handleDeleteNote={deleteNote}
          />
        </div>
      </section>
    </Layout>
  )
}

export default Notes
